import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import './DropdownMenu.scss';

export type DropdownMenuProps = {
  options: Array<string>;
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  setIsDropDownOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
  options,
  selectedOption,
  setSelectedOption,
  setIsDropDownOpen,
}) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const dropwdownMenuRef = useRef(null);

  useEffect(() => {
    const { y } = dropwdownMenuRef.current.getBoundingClientRect();
    const maxHeight = window.innerHeight - y;
    setMaxHeight(maxHeight);
  }, [dropwdownMenuRef]);

  const handleChange = (value: string) => {
    if (value === selectedOption) {
      setSelectedOption('');
    } else {
      setSelectedOption(value);
      setIsDropDownOpen(false);
    }
  };
  return (
    <div
      className="dropdown-menu-content"
      ref={dropwdownMenuRef}
      style={{ maxHeight: `${maxHeight}px` }}
    >
      {options.map((option: string) => (
        <span
          key={option}
          onClick={() => handleChange(option)}
          className={`dropdown-menu-option
          ${
            option.toLowerCase() === selectedOption.toLowerCase()
              ? 'selected'
              : ''
          }`}
        >
          {option}
        </span>
      ))}
    </div>
  );
};

export default DropdownMenu;
