import { useMemo } from 'react';

export const DOTS = 0;
interface usePaginationTypes {
  totalDataSetCount: number;
  pageSize: number;
  currentPage: number;
}
const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination: (
  usePaginationType: usePaginationTypes
) => number[] = ({ totalDataSetCount, pageSize, currentPage }) => {
  const siblingCount = 1;
  const paginationRange = useMemo(() => {
    const numberOfPages: number = Math.ceil(totalDataSetCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const pageNumbersToShow: number = siblingCount + 3;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..numberOfPages]
    */
    if (pageNumbersToShow >= numberOfPages) {
      return range(1, numberOfPages);
    }

    const leftSiblingIndex: number = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex: number = Math.min(
      currentPage + siblingCount,
      numberOfPages
    );

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots: boolean = leftSiblingIndex > 2;
    const shouldShowRightDots: boolean = rightSiblingIndex < numberOfPages - 1;

    const firstPageIndex = 1;
    const lastPageIndex = numberOfPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount: number = 2 + 2 * siblingCount;
      const leftRange: number[] = range(1, leftItemCount - 1);
      return [...leftRange, DOTS, numberOfPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 2 * siblingCount;
      const rightRange = range(numberOfPages - rightItemCount, numberOfPages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    if (!shouldShowLeftDots && !shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, ...middleRange, lastPageIndex];
    }
  }, [totalDataSetCount, pageSize, siblingCount, currentPage]);

  return paginationRange as number[];
};
