import React, { FunctionComponent } from 'react';

import './SearchResults.scss';

import { Dropdown } from '../Dropdown';
export const getLabelText = (resultCount: number): string => {
  let label = `${resultCount} results found`;

  if (resultCount === 1) {
    label = `${resultCount} result found`;
  }

  return label;
};

export type SearchResultsProps = {
  title: string;
  resultCount: number;
  sortByOptions: Array<string>;
  selectedSortValue: string;
  setSelectedSortValue: React.Dispatch<React.SetStateAction<string>>;
};

const SearchResults: FunctionComponent<SearchResultsProps> = ({
  title,
  resultCount,
  sortByOptions,
  selectedSortValue,
  setSelectedSortValue,
}) => {
  return (
    <div className="search-results-container">
      <h1 className="title">{title}</h1>
      <div className="lable-dropdown-container">
        <p className="label">{getLabelText(resultCount)}</p>
        <Dropdown
          label="Sort By"
          selectedOption={selectedSortValue}
          setSelectedOption={setSelectedSortValue}
          options={sortByOptions}
        />
      </div>
    </div>
  );
};

export default SearchResults;
