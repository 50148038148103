import React, { FunctionComponent, useState, useEffect, useRef } from 'react';

import './Dropdown.scss';

import { Icon } from '../Icon';
import { DropdownMenu } from './DropdownMenu';

export type DropdownProps = {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  options: Array<string>;
  label: string;
};

const Dropdown: FunctionComponent<DropdownProps> = ({
  selectedOption,
  setSelectedOption,
  options,
  label,
}) => {
  const [isDropdownOpen, setIsDropDownOpen] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, setIsDropDownOpen]);

  const onClickButton = () => {
    setIsDropDownOpen((prev) => !prev);
  };

  return (
    <div className="dropdown">
      <div ref={wrapperRef}>
        <button
          onClick={onClickButton}
          className={`dropdown-button ${selectedOption ? 'selected' : ''}`}
        >
          {selectedOption ? selectedOption : label}
          <Icon
            name="navigation-arrow"
            alt="filter-icon"
            className={`dropdown-button-icon ${isDropdownOpen && 'open'}`}
          />
        </button>
        {isDropdownOpen ? (
          <DropdownMenu
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setIsDropDownOpen={setIsDropDownOpen}
          />
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Dropdown);
