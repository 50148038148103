import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system';
import { useFlexSearch } from 'react-use-flexsearch';
import { graphql } from 'gatsby';

import './search.scss';

import { ArticleNavigation } from 'components/ArticleNavigation';
import { CallToAction } from 'components/CallToAction';
import { PrimaryArticleHorizontal } from 'components/PrimaryArticleHorizontal';
import { PrimaryLayout } from 'components/PrimaryLayout';
import { SearchResults } from 'components/SearchResults';
import { TertiaryArticleHorizontal } from 'components/TertiaryArticleHorizontal';
import { SideArticleList } from 'components/SideArticleList';
import { SocialCard } from 'components/SocialCard';

import { useAllContentfulArticle } from 'queries/article.queries';

import {
  orderArticlesAlphabeticallyByTitle,
  orderArticlesChronologicallyToNewest,
  orderArticlesChronologicallyToOldest,
} from '../utils/helpers';

const sortOptions = {
  Alphabetical: orderArticlesAlphabeticallyByTitle,
  'Date Added': orderArticlesChronologicallyToOldest,
  'Most Recent': orderArticlesChronologicallyToNewest,
  Popular: orderArticlesAlphabeticallyByTitle,
};

const SEARCH_RESULTS_PAGE_SIZE = 15;

export const query = graphql`
  query {
    localSearchPages {
      index
      store
    }
    allContentfulArticle(
      sort: { fields: publishedDate, order: DESC }
      filter: { subcategory: { id: { ne: null } } }
    ) {
      edges {
        node {
          id
          slug
          title
          subtitle
          subcategory {
            name
            category {
              name
            }
          }
          publishedDate(difference: "1970")
          thumbnailBam {
            data {
              target {
                fields {
                  title
                  description
                  file
                }
                sys {
                  id
                  linkType
                  type
                }
              }
            }
          }
          updatedAt(difference: "1970")
        }
      }
    }
  }
`;

export type SearchProps = {
  data: any;
  location: any;
};
const SearchPage: FunctionComponent<SearchProps> = ({
  data: {
    localSearchPages: { index, store },
  },
  location,
}) => {
  const query = new URLSearchParams(location.search).get('q');
  const allContentfulArticle = useAllContentfulArticle();
  const searchResults = useFlexSearch(query, index, store);

  const [page, setPage] = useState<number>(1);
  const [selectedSortValue, setSelectedSortValue] =
    useState<string>('Most Recent');
  const [sortedSearchResults, setSortedSearchResults] = useState(
    searchResults.sort(sortOptions[selectedSortValue])
  );

  useEffect(() => {
    setSortedSearchResults(searchResults);
  }, [searchResults]);

  const calculateArticleSliceToBeShown = () => {
    return [
      (page - 1) * SEARCH_RESULTS_PAGE_SIZE,
      page * SEARCH_RESULTS_PAGE_SIZE,
    ];
  };

  return (
    <PrimaryLayout>
      <Container
        className="search-page-container"
        fluid
        style={{ width: '100%', padding: 0 }}
      >
        <Row
          style={{ margin: 'auto', padding: 0 }}
          className="standard-max-width-container"
        >
          <Col className="results-container" lg={9} sm={12}>
            <Row style={{ margin: 15, padding: 0 }}>
              <SearchResults
                resultCount={sortedSearchResults.length}
                selectedSortValue={selectedSortValue}
                setSelectedSortValue={setSelectedSortValue}
                sortByOptions={Object.keys(sortOptions)}
                title={query || ''}
              />
            </Row>

            {sortedSearchResults
              .slice(...calculateArticleSliceToBeShown())
              .map((node) => {
                const {
                  category,
                  id,
                  title,
                  slug,
                  subtitle,
                  thumbnailBam,
                  subcategory,
                } = node;
                //const { description } = thumbnail;
                return (
                  <Row key={id} style={{ padding: '20px 0', margin: 0 }}>
                    <Hidden xs sm>
                      <PrimaryArticleHorizontal
                        articleText={subtitle || ''}
                        articleLink={slug}
                        categoryLabel={subcategory.name}
                        categoryLink={`${category.slug}/${subcategory.slug}`}
                        header={title}
                        image={thumbnailBam}
                        isSponsored={false}
                      />
                    </Hidden>
                    <Visible xs sm>
                      <TertiaryArticleHorizontal
                        articleLink={slug}
                        categoryLabel={subcategory.name}
                        categoryLink={`${category.slug}/${subcategory.slug}`}
                        header={title}
                        image={thumbnailBam}
                      />
                    </Visible>
                  </Row>
                );
              })}
            <ArticleNavigation
              currentPage={page}
              onPageChange={setPage}
              pageSize={SEARCH_RESULTS_PAGE_SIZE}
              totalDataSetCount={sortedSearchResults.length}
              type="primary"
            />
          </Col>
          <Col style={{ padding: 0 }} lg={3} sm={12}>
            <Hidden md sm xs>
              <SocialCard />
              <SideArticleList
                title="Popular"
                icon="categories-title-prefix"
                allContentfulArticle={allContentfulArticle}
              />
            </Hidden>
          </Col>
        </Row>
        <Row
          style={{ margin: 'auto' }}
          className="extended-max-width-container"
        >
          <Col style={{ padding: 0, width: '100%' }} sm={12} xs={12}>
            <div className="search-cta-container">
              <CallToAction
                body="Lorem ipsum dolor sit amet ipsum lorem ipsum dolor sit amet ipsum lorem..."
                ctaText="Subscribe"
                header="Receive the latest articles "
                inputPlaceholder="Enter email here..."
                onAction={() => null}
              />
            </div>
            <Visible md sm xs>
              <div style={{ margin: 10 }}>
                <SocialCard />
              </div>
            </Visible>
          </Col>
        </Row>
      </Container>
    </PrimaryLayout>
  );
};

export default SearchPage;
