import React, { FunctionComponent } from 'react';

import './ArticleNavigation.scss';
import { Icon } from '../Icon';

import { usePagination, DOTS } from './usePagination';

export type ArticleNavigationProps = {
  onPageChange: (newPage: number) => void;
  totalDataSetCount: number;
  currentPage: number;
  pageSize: number;
  className?: string;
  type: 'primary' | 'secondary' | null;
};

const ArticleNavigation: FunctionComponent<ArticleNavigationProps> = ({
  onPageChange,
  totalDataSetCount,
  currentPage,
  pageSize,
  className = '',
  type,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalDataSetCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const isFirstPage = currentPage === 1;
  const isLastPage =
    currentPage === paginationRange[paginationRange.length - 1];

  return (
    <div className={`pagination-container ${className} ${type}`}>
      <button
        className={`pagination-item ${isFirstPage ? 'disabled' : ' '}`}
        onClick={() => onPageChange(currentPage - 1)}
        aria-disabled={isFirstPage ? 'true' : 'false'}
      >
        <Icon
          className="arrow left"
          name="navigation-arrow"
          alt="Previous Article Page Button"
        />
        <p className="text">BACK</p>
      </button>

      <ul className={`list-container`}>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} className="pagination-item dots">
                {[1, 2, 3].map((el) => (
                  <span key={el} className="dot"></span>
                ))}
              </li>
            );
          }

          return (
            <li
              key={index}
              className={`pagination-item ${
                currentPage === pageNumber && 'selected'
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      </ul>

      <button
        className={`pagination-item ${isLastPage ? 'disabled' : ' '}`}
        onClick={() => onPageChange(currentPage + 1)}
        aria-disabled={isLastPage ? 'true' : 'false'}
      >
        <p className="text">NEXT</p>
        <Icon
          className="arrow right"
          name="navigation-arrow"
          alt="Next Article Page Button"
        />
      </button>
    </div>
  );
};

export default ArticleNavigation;
